import React, { Suspense } from "react";
import { BrowserRouter, Navigate, useRoutes } from 'react-router-dom';
import { useInstance } from 'react-ioc';
import { UserStore } from 'stores';
import { observer } from 'mobx-react';
import { useSchool } from 'components';
import { LoadingOverlay } from "@axl/accel-framework/components";

const AuthLayout = React.lazy(() => import('App/layouts/AuthLayout/AuthLayout'));
const BasicLayout = React.lazy(() => import('App/layouts/BasicLayout/BasicLayout'));
const LearnLayout = React.lazy(() => import('App/layouts/LearnLayout/LearnLayout'));

const Login = React.lazy(() => import('views/auth/components/Login/Login'));
const LoginToken = React.lazy(() => import('views/auth/components/LoginToken/LoginToken'));
const Register = React.lazy(() => import('views/auth/components/Register/Register'));
const ForgotPassword = React.lazy(() => import('views/auth/components/ForgotPassword/ForgotPassword'));
const ResetPassword = React.lazy(() => import('views/auth/components/ResetPassword/ResetPassword'));
const LearnPage = React.lazy(() => import('views/learn/pages/Learn/LearnPage'));
const ConfirmEmail = React.lazy(() => import('views/auth/components/ConfirmEmail/ConfirmEmail'));
const ConfirmInvite = React.lazy(() => import('views/auth/components/ConfirmInvite/ConfirmInvite'));
const PreviewPage = React.lazy(() => import('views/learn/pages/Preview/PreviewPage'));
const StudentPreviewPage = React.lazy(() => import('views/learn/pages/StudentPreview/StudentPreviewPage'));
const ForumPage = React.lazy(() => import('views/forum/pages/ForumPage'));

const UnsubscribeEmails = React.lazy(() => import('views/auth/components/UnsubscribeEmails/UnsubscribeEmails'));
const LearnLibraryPage = React.lazy(() => import('views/library/pages/Library/LibraryPage'));
const SchoolMaterialsPage = React.lazy(() => import('views/school-materials/pages/SchoolMaterialsPage/SchoolMaterialsPage'));
const LoginAs = React.lazy(() => import('views/auth/components/LoginAs/LoginAs'));
const AuthContactPage = React.lazy(() => import("views/auth/components/AuthContact/AuthContactPage"));
const BillingPage = React.lazy(() => import('views/billing/pages/BillingPage'));
const UnsubscribeEmailNotifications = React.lazy(() => import("views/auth/components/UnsubscribeEmailNotifications/UnsubscribeEmailNotifications"));
const AccessPage = React.lazy(() => import('views/access/pages/Access/AccessPage'));
const PartnershipPage = React.lazy(() => import("views/partnership/pages/Partnership/PartnershipPage"));
const GamificationPage = React.lazy(() => import("views/gamification/pages/GamificationPage"));

const BasicLayoutRouting: React.FC = () => {
    const { isAuthenticated } = useInstance(UserStore);
    const school = useSchool();

    if (!isAuthenticated)
        return <Navigate to={`/login?returnUrl=${location.getReferral()}`} />;

    // billing lock
    if (school.isLocked && !location.pathname.startsWith('/locked'))
        return <Navigate to={`/locked`} />;

    // migration lock
    const hasMigrationLock = school.hasMigrationLock ?? false;
    if (hasMigrationLock && location.pathname != '/')
        return <Navigate to={`/`} />;


    return <Suspense fallback={<LoadingOverlay />}><BasicLayout /></Suspense>;
}


const Router = observer(() => {
    const { isAuthenticated } = useInstance(UserStore);

    // We removed the <BrowserRouter> element from App because the
    // useRoutes hook needs to be in the context of a <BrowserRouter>
    // element. This is a common pattern with React Router apps that
    // are rendered in different environments. To render an <App>,
    // you'll need to wrap it in your own <BrowserRouter> element.
    const element = useRoutes([
        // A route object has the same properties as a <Route>
        // element. The `children` is just an array of child routes.
        {
            path: '/',
            element: <BasicLayoutRouting />,
            children: [
                {
                    path: '/', element: <Suspense fallback={<LoadingOverlay />}><SchoolMaterialsPage /></Suspense>
                },
                { path: '/courses', element: <Navigate to={'/'} /> },

                {
                    path: '/access', element: <Suspense fallback={<LoadingOverlay />}><AccessPage /></Suspense>
                },

                {
                    path: '/partnership', element: <Suspense fallback={<LoadingOverlay />}><PartnershipPage /></Suspense>
                },
                {
                    path: '/gamification', element: <Suspense fallback={<LoadingOverlay />}><GamificationPage /></Suspense>
                },
                {
                    path: '/forum', element: <Suspense fallback={<LoadingOverlay />}><ForumPage /></Suspense>
                },
                {
                    path: '/forum/:threadId', element: <Suspense fallback={<LoadingOverlay />}><ForumPage /></Suspense>
                },
                {
                    path: '/forum/:threadId/:topicId', element: <Suspense fallback={<LoadingOverlay />}><ForumPage /></Suspense>
                },

                { path: '/locked', element: <Suspense fallback={<LoadingOverlay />}><BillingPage /></Suspense> },
            ]
        },
        {
            path: '/',
            element: <Suspense fallback={<LoadingOverlay />}><AuthLayout /></Suspense>,
            children: [
                { path: '/login', element: <Suspense fallback={<LoadingOverlay />}><Login /></Suspense> },
                { path: '/login-token/:token', element: <Suspense fallback={<LoadingOverlay />}><LoginToken /></Suspense> },
                { path: '/login-as/:sessionId/:studentId/:timestamp/:signature', element: <Suspense fallback={<LoadingOverlay />}><LoginAs /></Suspense> },

                { path: '/register', element: <Suspense fallback={<LoadingOverlay />}><Register /></Suspense> },
                { path: '/c/:contactId/:inviteToken?', element: <Suspense fallback={<LoadingOverlay />}><AuthContactPage /></Suspense> },
                { path: '/forgot-password', element: <Suspense fallback={<LoadingOverlay />}><ForgotPassword /></Suspense> },
                { path: '/reset-password/:token', element: <Suspense fallback={<LoadingOverlay />}><ResetPassword /></Suspense> },

                { path: '/confirm-email/:userId/:token', element: <Suspense fallback={<LoadingOverlay />}><ConfirmEmail /></Suspense> },
                { path: '/confirm-invite/:userId/:token', element: <Suspense fallback={<LoadingOverlay />}><ConfirmInvite /></Suspense> },
                { path: '/email/unsubscribe', element: <Suspense fallback={<LoadingOverlay />}><UnsubscribeEmails /></Suspense> },
                { path: '/email-notification/unsubscribe', element: <Suspense fallback={<LoadingOverlay />}><UnsubscribeEmailNotifications /></Suspense> }
            ]
        },
        {
            path: '/',
            element: isAuthenticated
                ? <Suspense fallback={<LoadingOverlay />}><LearnLayout /></Suspense>
                : <Navigate to={`/login?returnUrl=${location.getReferral()}`} />,
            children: [
                { path: '/learn/:courseItemId', element: <Suspense fallback={<LoadingOverlay />}><LearnPage /></Suspense> },
                { path: '/learn/:courseItemId/:tab', element: <Suspense fallback={<LoadingOverlay />}><LearnPage /></Suspense> },

                { path: '/course-preview/:courseId', element: <Suspense fallback={<LoadingOverlay />}><PreviewPage /></Suspense> },
                { path: '/course-preview/:courseId/:courseItemId', element: <Suspense fallback={<LoadingOverlay />}><PreviewPage /></Suspense> },
                { path: '/course-preview/:courseId/:courseItemId/:tab', element: <Suspense fallback={<LoadingOverlay />}><PreviewPage /></Suspense> },

                { path: '/student-course-preview/:studentCourseId', element: <Suspense fallback={<LoadingOverlay />}><StudentPreviewPage /></Suspense> },
                { path: '/student-course-preview/:studentCourseId/:courseItemId', element: <Suspense fallback={<LoadingOverlay />}><StudentPreviewPage /></Suspense> },
                { path: '/student-course-preview/:studentCourseId/:courseItemId/:tab', element: <Suspense fallback={<LoadingOverlay />}><StudentPreviewPage /></Suspense> },

                { path: '/library/:libraryId', element: <Suspense fallback={<LoadingOverlay />}><LearnLibraryPage /></Suspense> },
                { path: '/library/:libraryId/:libraryItemId', element: <Suspense fallback={<LoadingOverlay />}><LearnLibraryPage /></Suspense> },
            ]
        },
        {
            path: '*',
            element: <Navigate to={'/'} />,
        }
    ]);

    return element;
})


const LayoutSelector: React.FC = () => {
    return <BrowserRouter>
        <Router />
    </BrowserRouter>;
};
export default LayoutSelector;