import ApiSettings from "./api-settings";
import LocalizationSettings from './localization-settings';
import AWSSettings from './aws-settings';
import HubSettings from './hub-settings';
import ChatSettings from "./chat-settings";
import NewRelicSettings from "./newrelic-settings";
import QuizSettings from './QuizSettings';
import CheckoutSettings from './checkout';

export default class AppSettings {
    public api: ApiSettings;
    public localization: LocalizationSettings;
    public aws: AWSSettings;
    public hub: HubSettings;
    public tokenUpdateIntervalMin: number;
    public chat: ChatSettings;
    public newRelic: NewRelicSettings;
    public quiz: QuizSettings;
    public checkout: CheckoutSettings;

    constructor(settings: any) {
        this.api = new ApiSettings(settings.api);
        this.localization = new LocalizationSettings(settings.localization);
        this.aws = new AWSSettings(settings.aws);
        this.hub = new HubSettings(settings.hub);
        this.tokenUpdateIntervalMin = settings.tokenUpdateIntervalMin;
        this.chat = new ChatSettings(settings.chat);
        this.newRelic = new NewRelicSettings(settings.newRelic);
        this.quiz = new QuizSettings(settings.quiz);
        this.checkout = new CheckoutSettings(settings.checkout);
    }
}